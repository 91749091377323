/** @jsx jsx */
import { jsx, Box, Container, Grid, Styled} from 'theme-ui'
import {graphql} from 'gatsby'
import Img from "gatsby-image"
import SEO from "../components/seo"

const AboutUs = ({data}) => {

  const chris = data.chris
  return (
    <section>
      <SEO title="Thank You" />
      <Container>
        <Box>
          <Container sx={{mx: 'auto', width: '100%', maxWidth: 'medium', py: 5}}>
            <Grid columns={[1]} sx={{alignItems: 'center'}}>
              <Box sx={{mx: 5, order: [1,1,0]}}>
                <Img fluid={chris.childImageSharp.fluid}  sx={{width: "100%"}} />
                <Styled.p sx={{textAlign: 'center', mt: 0}}>Me with two of our Golden Retriever pups. (1999)</Styled.p>
              </Box>
              <Box sx={{mx: 5, order: [0,0,1]}}>
                <Styled.h3 sx={{textAlign: 'center'}}>Why Barkpedia?</Styled.h3>
                  <Styled.p>I grew up with dogs. We had Golden Retrievers that we were fortunate enough to be able to breed for a few years. I was able to experience and see first-hand the joy that a dog can bring.</Styled.p>

                  <Styled.p>I now have a family of my own and we have been on the search for our own first dog. It’s been fun researching different breeds and looking at pictures with my sons.</Styled.p>

                  <Styled.p>But, during this process, I’ve noticed that there is a lot of dog breed misinformation and misconceptions being shared across the internet. This misinformation hurts dogs and is why so many of them end up abandoned, put in shelters, and euthanized. </Styled.p>

                  <Styled.p>I’ve heard terrible stories of people giving up on their pets and dropping them off in fields or in random neighborhoods because they ultimately decided the dog wasn't for them. </Styled.p>

                  <Styled.p>My goal, and hopefully yours, is to help prevent this by ending the spread of dog breed misinformation by creating the best dog encyclopedia and community on the internet. In return, I hope this information will help more people find the right dog for their situation.</Styled.p>

                  <Styled.p>#KnowYourBreed</Styled.p>
                  <Styled.p sx={{fontWeight: 'bold', mb: '0px'}}>Chris Collinsworth</Styled.p>
                  <Styled.p sx={{fontWeight: 'bold', fontSize: '10px'}}>Founder, Barkpedia.com</Styled.p>
              </Box>
            </Grid>
          </Container>
        </Box>
      </Container>
    </section>
  )
}

export default AboutUs

export const query = graphql`
  query {

    chris: file(relativePath: { eq: "christmaspups.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }  

  }
`